import CMSChildrenContainer from '@/cmsComponents/ChildrenContainer';
import { Alignment } from '@/cmsComponents/constants';
import cmsStyles from '@/cmsComponents/style.module.scss';
import React from 'react';
import { IText } from 'types/cms';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Item } from './item';
import styles from './style.module.scss';

export enum Style {
  Three = 'Three_Column',
  Two = 'Two_Column',
  One = 'Full_Width',
  TwoThree = 'One_Column',
}

interface ITextProps {
  id: number;
  heading?: string;
  layout: Style;
  alignment: Alignment;
  background: boolean;
  text_collections?: Array<IText>;
  text_collection?: IText;
}

const styleToGridColumn = (layout: Style) => (layout === Style.Three ? 4 : 6);

export const CMSText: React.FC<ITextProps> = ({
  alignment,
  layout,
  heading,
  background,
  text_collections,
  text_collection,
}) => {
  return (
    <CMSChildrenContainer>
      <div
        className={
          layout === Style.TwoThree ? cmsStyles.containerMarginL : cmsStyles.containerMarginM
        }
      >
        {heading && (
          <Typography variant="h4" component="h2" className={styles.title}>
            {heading}
          </Typography>
        )}
        {text_collections && (
          <Grid container spacing={6}>
            {text_collections.map(i => (
              <Grid key={i.id} item xs={12} md={styleToGridColumn(layout)}>
                <Item data={i} alignment={alignment} style={layout} background={background} />
              </Grid>
            ))}
          </Grid>
        )}
        {text_collection && (
          <div className={layout === Style.TwoThree ? styles.One_Column : ''}>
            <Item
              data={text_collection}
              alignment={alignment}
              style={layout}
              background={background}
            />
          </div>
        )}
      </div>
    </CMSChildrenContainer>
  );
};
