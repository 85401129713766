import { LinkElement } from '@/cmsComponents/LinkElement';
import MarkdownCMSModule from '@/cmsComponents/MarkdownCMSModule';
import { Alignment } from '@/cmsComponents/constants';
import cx from 'classnames';
import React from 'react';
import { IText } from 'types/cms';

import Typography from '@material-ui/core/Typography';

import { Style } from './index';
import styles from './style.module.scss';

export interface IItem {
  data: IText;
  alignment: Alignment;
  style: Style;
  background: boolean;
}

export const Item: React.FC<IItem> = ({ data, alignment, style, background }) => {
  const { title, blurb, button_text, button_URL, button_style } = data;
  const isMultiItemLayout = style === Style.Three || style === Style.Two;
  return (
    <div
      className={cx(
        styles.itemContainer,
        styles[alignment],
        background ? styles.bg : '',
        isMultiItemLayout ? styles.multiItems : '',
      )}
    >
      <div>
        {title && (
          <Typography variant="h5" component="h3" className={styles.itemTitle}>
            {title}
          </Typography>
        )}
        {blurb && (
          <div className={styles.blurb}>
            <MarkdownCMSModule source={blurb} />
          </div>
        )}
      </div>
      <div>
        {button_URL && (
          <LinkElement
            linkText={button_text}
            link={button_URL}
            linkStyle={button_style}
            align={alignment}
          />
        )}
      </div>
    </div>
  );
};
